/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"


const getSchemaPersonnJSONLD = ({
    authorName,
    authordescription,
    authorimage,
    authorurl,
    authorSocial
}) => ({
    "@context": "http://schema.org",
    "@type": "Person",
    "name": authorName,
    "description": authordescription,
    "image": authorimage,
    "url": authorurl,
    "sameAs": authorSocial
})

const getSchemaOrgJSONLD = ({
    url,
    title,
    image,
    description,
    datePublished,
    dateModified,
    siteTitle,
    siteUrl,
    shortName,
}) => ({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": title,
    "description": description,
    "image": [
        image,
    ],
    "datePublished": "2020-01-01T23:28:39",
    "dateModified": dateModified,
    "publisher": {
        "@type": "Organization",
        "name": shortName,
        "url": siteUrl,
        "logo": {
            "@type": "ImageObject",
            "url": "https://www.thaimarket.net/static/thaimarket-582f2ac4dcf69efe467292a97b899151.png",
            "width": 125,
            "height": 30,
        },
        
        "sameAs": [
            "https://www.pinterest.com/thaimarket",
            "https://www.twitter.com/thaimarket",

        ]
    }
});



function SEO({ canonical, description, lang, meta, title, image, datePublished, dateModified, authorName, authordescription,
    authorimage,
    authorurl,
    authorSocial }) {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            shortName
          }
        }
      }
    `
    )
    const siteTitle = site.siteMetadata.title;
    const siteUrl = site.siteMetadata.sitesiteUrl;
    const shortName = site.siteMetadata.shortName;
    const schemaOrgJSONLD = authorName ? getSchemaPersonnJSONLD({
        authorName,
        authordescription,
        authorimage,
        authorurl,
        authorSocial
    })
        : getSchemaOrgJSONLD({
            canonical,
            title,
            image,
            description,
            datePublished,
            dateModified,
            siteTitle,
            siteUrl,
            shortName,
        });

    const metaDescription = description || site.siteMetadata.description

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s`}
            link={
                canonical
                    ? [{ rel: 'canonical', key: canonical, href: canonical }]
                    : []
            }
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `image`,
                    content: image ? image : '',
                },
                {
                    name: `og:url`,
                    content: canonical,
                },
                {
                    property: `og:type`,
                    content: 'website',
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: image ? image : '',
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:image`,
                    content: image ? image : '',
                },
                {
                    name: `p:domain_verify`,
                    content: "",
                },
                {
                    name: `google-site-verification`,
                    content: "",
                },
                {
                    name: `robots`,
                    content: authorName ? 'noindex,follow' : 'index,follow',
                }
            ].concat(meta)}
            script={[
                {
                    type: 'application/ld+json',
                    innerHTML: `${JSON.stringify(schemaOrgJSONLD)}`
                }
            ]}
        />
    )
}

SEO.defaultProps = {
    lang: `vi`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    canonical: PropTypes.string,
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
